import * as React from 'react';
import { ReactHTML } from 'react';

const mergeDedupe = (...arrays) => [...new Set([].concat(...arrays))];

const defaultClassNames = ['appearance-none', 'border', 'rounded-lg', 'w-full', 'py-2', 'px-3',
  'text-gray-700', 'leading-tight', 'focus:outline-none', 'focus:shadow-outline'];

export const StyledInput: React.FC<React.ComponentProps<ReactHTML['input']>> = (props) => {
  const { className, ...rest } = props;
  return (
    <input
      className={mergeDedupe(defaultClassNames, className.split(' ') || '').join(' ')}
      {...rest}
    />
  );
};
