import React from 'react';
import { SubpageLayout } from '../components/SubpageLayout';
import { Seo } from '../components/Seo';
import { ContactForm } from '../components/ContactForm/ContactForm';

const ContactPage = () => (
  <SubpageLayout
    title="Kontakt"
  >
    <Seo
      title="Formularz kontaktowy"
      description="Wyślij zapytanie dotyczące aplikacji FAKTURUJ.TO"
      keywords={['kontakt', 'feedback', 'uwagi']}
    />
    <ContactForm
      url={'https://api.test.fakturuj.to/app-service-desk/issues/'}
      thankyou_url="https://fakturuj.to/kontakt-podziekowanie"
    />
  </SubpageLayout>
);

export default ContactPage;
